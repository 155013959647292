


export class BrowserAppEvaluationToolInfo {

  public version = '2024.10.29.1';

  constructor() {
  }

}
